import styled from "styled-components";
import emailjs from 'emailjs-com';
import {
    apple,
    apple_footer,
    background1,
    footer1,
    google,
    google_footer,
    main2,
    main3_contents,
    main3_image,
    main3_message,
    main3_title,
    main4_1_check,
    main4_1_contents,
    main4_1_image,
    main4_1_title,
    main4_bell,
    main4_contents,
    main4_image, main4_imageM,
    main4_title,
    main5_contents,
    main5_image, main5_imageM,
    main5_live,
    main5_title,
    main6_contents,
    main6_image,
    main6_title,
    main7_agree,
    main7_contents,
    main7_title
} from "../../images";
import {useRef, useState} from "react";

const Main1 = styled.div`
  width: 100%;
  position: absolute;
  min-width: 900px;
`;

const Body = styled.div`
  padding: 21% 0 3% 20%;
`;

const Body2 = styled.div`
  padding: 7% 0 0 43%;
`;

const Store = styled.div`
  display: flex;
  flex-direction: row;
  
  img {
    width: 11%;
    cursor: pointer;
  }
`;
const Main2 = styled.div`
  margin: 10% auto;
  min-width: 900px;
`;

const Main3 = styled.div`
  background-color: #e6f2fe;
  padding: 5% 0 0 0;
  min-width: 900px;
`;

const Main4_1 = styled.div`
  background-color: white;
  padding: 5% 0;
  min-width: 900px;
`;

const Main4 = styled.div`
  background-color: #f0f0f0;
  padding: 5% 0;
  min-width: 900px;
`;

const Main5 = styled.div`
  background-color: white;
  padding: 7% 0;
  min-width: 900px;
`;

const Main6 = styled.div`
  background-color: #e6f2ff;
  padding: 7% 0;
  min-width: 900px;
`;

const Main7 = styled.div`
  background-color: white;
  min-width: 900px;
  margin-top: 7%;
`;

const Footer = styled.div`
  width: 100%;
  position: absolute;
  min-width: 900px;
`;

const InBox = styled.div`
    text-align: center;
    margin: 0 auto;
    max-width: 2000px;
`;

const InBox2 = styled.div`
    width: 35%;
    text-align: center;
    margin: 0 auto;
    max-width: 2000px;
    display: flex;
    flex-direction: column;
`;

const InBox5_2 = styled.div`
    width: 45%;
    text-align: center;
    margin: 0 auto;
    max-width: 2000px;
    display: flex;
    flex-direction: column;
`;

const Col3 = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 5%;
`;

const Content = styled.div`
  width: 55%;
  margin: 0 auto;
  max-width: 2000px;
  
  textarea {
    width: 95%;
    height: 300px;
    border: 1px solid #d6d6d6;
    background-color: white;
    border-radius: 10px;
    font-size: 1.2rem;
    margin-top: 3%;
    resize: none;
  }

  input {
    width: 35%;
    height: 50px;
    border: none;
    border-bottom: 2px solid #d6d6d6;
    background-color: white;
    font-size: 1.2rem;
  }
`;

const BoxRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 3%;
`;

const Font = styled.div`
    margin: auto 0;
    font-size: 1.2rem;
    width: 18%;
    font-weight: bold;
    color: #404040;
`;

const Font2 = styled.div`
    margin: auto 0;
    font-size: 1.2rem;
`;

const Box = styled.div`
    display: flex;
    margin: 0 auto;
    max-width: 2000px;
`;

const Right = styled.div`
    width: 60%;
`;
const Left = styled.div`
    text-align: center;
    width: 40%;
    padding-top: 5%;
    padding-left: 20%;
    display: flex;
    flex-direction: column;
`;

const Left2 = styled.div`
  width: 60%;
`;

const Right2 = styled.div`
  text-align: center;
  width: 40%;
  padding-top: 5%;
  padding-right: 20%;
`;

const Left4 = styled.div`
  width: 60%;
`;

const Right4 = styled.div`
  text-align: center;
  width: 40%;
  padding-top: 7%;
  padding-right: 20%;
`;

const Left3 = styled.div`
  width: 55%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
`;

function LandingWeb() {
    const form = useRef();
    const [checked, setChecked] = useState(false);
    const [secondChecked, setSecondChecked] = useState(true);
    const [email, setEmail] = useState('');
    const [content, setContent] = useState('');

    const sendEmail = (e) => {
        if(!email) {
            alert('이메일을 입력해주세요.');
            return;
        }
        if(!content) {
            alert('문의내용을 입력해주세요.');
            return;
        }
        if (checked === true) {
            e.preventDefault();

            emailjs.sendForm(
                'academyNow',
                'template_ew5i0nx',
                form.current,
                '2PKXd3vUPIWP02V9D'
            )
                .then((result) => {
                    alert('문의를 완료했습니다.');
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });

        } else {
            alert('개인 정보 수집·이용에 동의해주세요.')
        }
    };

    const handleMoveGoogle = async () => {
        document.location.href = 'https://play.google.com/store/apps/details?id=com.rightcode.academynow_user';
    };
    const handleMoveApple = async () => {
        document.location.href = 'https://apps.apple.com/kr/app/%ED%95%99%EC%9B%90%EB%82%98%EC%9A%B0-%ED%95%99%EC%9B%90%EC%8B%A4%EC%8B%9C%EA%B0%84%EB%9D%BC%EC%9D%B4%EB%B8%8C-%EB%B0%B0%EC%B0%A8-%EC%B6%9C%EC%84%9D-%EA%B3%B5%EC%A7%80%EB%A5%BC%ED%95%9C%EB%B2%88%EC%97%90/id1629210905';
    };

    const diff1 = () => {
        setChecked(true);
        setSecondChecked(false);
    };
    const diff2 = () => {
        setChecked(false);
        setSecondChecked(true);
    };

    return (
        <>
            <Main1>
                <Body>
                    <Store>
                        <img src={google} onClick={(e) => handleMoveGoogle(e)}/>
                        <img src={apple} style={{ marginLeft: '3%'}} onClick={(e) => handleMoveApple(e)}/>
                    </Store>
                </Body>
            </Main1>
            <img src={background1} style={{ width: '100%', minWidth: '900px'}}/>

            <Main2>
                <InBox>
                    <img src={main2} style={{ width: '60%' }}/>
                </InBox>
            </Main2>

            <Main3>
                <Box>
                    <Left>
                        <img src={main3_message} style={{ width: '13%',marginLeft: '80%' }}/>
                        <img src={main3_title} style={{ width: '80%', marginBottom: '5%' }}/>
                        <img src={main3_contents} style={{ width: '80%' }}/>
                    </Left>
                    <Right>
                        <div style={{textAlign: 'left'}}>
                            <img src={main3_image} style={{ width: '60%' }}/>
                        </div>
                    </Right>
                </Box>
            </Main3>

            <Main4_1>
                <Box>
                    <Left4>
                        <div style={{ textAlign: 'right' }}>
                            <img src={main4_1_image} style={{ width: '60%' }}/>
                        </div>
                    </Left4>
                    <Right4>
                        <div style={{textAlign: 'right'}}>
                             <img src={main4_1_check} style={{ width: '10%', marginLeft: '70%', marginBottom: '5%' }}/>
                            <img src={main4_1_title} style={{ width: '60%', marginBottom: '5%' }}/>
                            <img src={main4_1_contents} style={{ width: '80%' }}/>
                        </div>
                    </Right4>
                </Box>
            </Main4_1>

            <Main4>
                <Box>
                    <Left2>
                        <div style={{ textAlign: 'right' }}>
                            <img src={main4_imageM} style={{ width: '55%' }}/>
                        </div>
                    </Left2>
                    <Right2>
                        <div style={{textAlign: 'right'}}>
                            <img src={main4_bell} style={{ width: '10%', marginLeft: '70%', marginBottom: '5%' }}/>
                            <img src={main4_title} style={{ width: '70%', marginBottom: '5%' }}/>
                            <img src={main4_contents} style={{ width: '80%' }}/>
                        </div>
                    </Right2>
                </Box>
            </Main4>

            <Main5>
                <InBox5_2>
                    <div>
                        <img src={main5_live} style={{ width: '12%', margin: '0 auto', paddingRight: '3%' }}/>
                        <img src={main5_title} style={{ width: '55%', margin: '0 auto' }}/>
                    </div>
                    <img src={main5_contents} style={{ margin: '5% auto 3% ', width: '70%' }}/>
                    <img src={main5_imageM} />
                </InBox5_2>
            </Main5>

            <Main6>
                <InBox2>
                    <img src={main6_title} style={{ width: '70%', margin: '0 auto' }}/>
                    <img src={main6_contents} style={{ margin: '10% 0 13% 0 ' }}/>
                    <img src={main6_image}/>
                </InBox2>
            </Main6>

            <Main7>
                <Content>
                    <form ref={form}>
                        <Col3>
                            <img src={main7_title} style={{ width: '45%' }}/>
                            <img src={main7_contents} style={{ width: '50%', marginTop: '1%' }}/>
                        </Col3>
                        <BoxRow>
                            <Font style={{ width: '15.5%' }}>이름</Font>
                            <input type="text" name="from_name" style={{ width: '30%' }} placeholder="홍길동" />
                        </BoxRow>
                        <BoxRow>
                            <Font>이메일</Font>
                            <input type="email" name="user_email" value={email} placeholder="이메일을 입력해주세요" onChange={(e) => setEmail(e.target.value)} />
                            <div style={{ width: '10%'}}/>
                            <Font>전화번호</Font>
                            <input type="tel" name="user_tel" placeholder="-없이 숫자만" />
                        </BoxRow>
                        <BoxRow>
                            <Font>내용</Font>
                            <textarea name="message" placeholder="내용을 입력해주세요." value={content} onChange={(e) => setContent(e.target.value)}/>
                        </BoxRow>
                    </form>
                </Content>
                <Left3>
                    <Font2>개인 정보 수집·이용에 동의하십니까?</Font2>
                    <div style={{ marginLeft: 'auto', height: '10%' }}>
                        <label>
                            <input style={{  }} type="radio" name="chk_info" checked={checked} onClick={diff1}/>동의&nbsp;&nbsp;
                        </label>
                        <label>
                            <input style={{  }} type="radio" name="chk_info" checked={secondChecked} onClick={diff2}/>비동의
                        </label>
                    </div>
                </Left3>
                <div style={{ textAlign: 'center', marginBottom: '5%' }}>
                    <img src={main7_agree} style={{ width: '15%', cursor: 'pointer', marginTop: '5%' }} onClick={sendEmail}/>
                </div>
            </Main7>


            <Footer>
                <Body2>
                    <Store>
                        <img src={google_footer} onClick={(e) => handleMoveGoogle(e)}/>
                        <img src={apple_footer} style={{ marginLeft: '3%'}} onClick={(e) => handleMoveApple(e)}/>
                    </Store>
                </Body2>
            </Footer>
            <img src={footer1} style={{ width: '100%' }}/>
        </>
    )
}

export default LandingWeb;