import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import LandingWeb from "./pages/Main/landingWeb";
import LandingMobile from "./pages/Main/landingMobile";

class Routes extends Component {
    render() {
        let indexRoute;
        if (isMobile) {
            indexRoute = <Route exact path='/' component={LandingMobile} />;
        } else {
            indexRoute = <Route exact path='/' component={LandingWeb} />;
        }
        return (
            <Router>
                <Switch>
                    {indexRoute}
                    {/*<Route exact path='/' component={LandingWeb} />;*/}
                    {/*<Route exact path='/m' component={LandingMobile} />;*/}
                </Switch>
            </Router>
        );
    }
}

export default Routes;
