import styled from "styled-components";
import {
    apple,
    apple_footer,
    background1M,
    footerM,
    google,
    google_footer,
    main1_imageM,
    main1_titleM,
    main2_imageM,
    main3_contentsM,
    main3_imageM,
    main3_messageM,
    main3_titleM,
    main4_1_check,
    main4_1_contentsM,
    main4_1_image,
    main4_1_title,
    main4_bellM,
    main4_contentsM,
    main4_imageM,
    main4_titleM,
    main5_contentsM,
    main5_imageM,
    main5_liveM,
    main5_titleM,
    main6_contentsM,
    main6_imageM,
    main6_titleM,
    main7_agreeM,
    main7_contentsM,
    main7_titleM
} from "../../images";
import {useRef, useState} from "react";
import emailjs from "emailjs-com";

const Main1 = styled.div`
  width: 100%;
  padding: 1rem 0 6rem 0;
  position: absolute;
`;

const Header = styled.div`
  height: 15%;
  display: flex;
  justify-content: space-between;
  padding: 0 3%;

  img {
    width: 1.5rem;
  }
`;

const Store = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
`;

const Main2 = styled.div`
  width: 90%;
  text-align: center;
  margin: 0 auto;
  padding: 40px 0;
`;

const Main3 = styled.div`
  background-color: #e6f2fe;
  padding: 15% 0 0 0;
`;

const Main4_1 = styled.div`
  background-color: white;
  padding: 15% 0 0 0;
`;

const Main4 = styled.div`
  background-color: #f0f0f0;
  padding: 15% 0 0 0;
`;

const Main5 = styled.div`
  background-color: white;
  padding: 10% 0 10% 0;
`;

const Main6 = styled.div`
  background-color: #e6f2ff;
  padding: 20% 0;
`;

const Main7 = styled.div`
  background-color: white;
  padding: 10% 0;
`;

const Content = styled.div`
  background-color: white;
  
  textarea {
         width: 60%;
         height: 15rem;
         border: 1px solid #d6d6d6;
         font-size: 1.3rem;
         //margin-top: 5%;
         margin-bottom: 10%;
         resize: none;
        color: #c1c1c1;
    border-radius: 7px;
   }
  
    input {
        width: 60%;
        height: 2.3rem;
        border: none;
        border-bottom: 1px solid #d6d6d6;
        color: #c1c1c1;
        font-size: 1.3rem;
    }
`;

const BoxRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 3%;
  margin-top: 5%;
`;

const Font = styled.div`
    font-size: 1.1rem;
    width: 20%;
    font-weight: bold;
    color: #404040;
    margin-left: 10%;
  margin-top: 2%;
`;

const Font2 = styled.div`
    margin: auto 0;
    font-size: 1.5rem;
  text-align: center;
`;

const Footer = styled.div`
  width: 100%;
  padding: 20% 0 0 0;
  position: absolute;
`;

function LandingMobile() {
    const form = useRef();
    const [checked, setChecked] = useState(false);
    const [secondChecked, setSecondChecked] = useState(true);
    const [email, setEmail] = useState('');
    const [content, setContent] = useState('');

    const sendEmail = (e) => {
        if(!email) {
            alert('이메일을 입력해주세요.');
            return;
        }
        if(!content) {
            alert('문의내용을 입력해주세요.');
            return;
        }
        if (checked === true) {
            e.preventDefault();

            emailjs.sendForm(
                'academyNow',
                'template_ew5i0nx',
                form.current,
                '2PKXd3vUPIWP02V9D'
            )
                .then((result) => {
                    alert('문의를 완료했습니다.');
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });

        } else {
            alert('개인 정보 수집·이용에 동의해주세요.')
        }
    };


    const diff1 = () => {
        setChecked(true);
        setSecondChecked(false);
    };
    const diff2 = () => {
        setChecked(false);
        setSecondChecked(true);
    };

    const handleMoveGoogle = async () => {
        document.location.href = 'https://play.google.com/store/apps/details?id=com.rightcode.academynow_user';
    };
    const handleMoveApple = async () => {
        document.location.href = 'https://apps.apple.com/kr/app/%ED%95%99%EC%9B%90%EB%82%98%EC%9A%B0-%ED%95%99%EC%9B%90%EC%8B%A4%EC%8B%9C%EA%B0%84%EB%9D%BC%EC%9D%B4%EB%B8%8C-%EB%B0%B0%EC%B0%A8-%EC%B6%9C%EC%84%9D-%EA%B3%B5%EC%A7%80%EB%A5%BC%ED%95%9C%EB%B2%88%EC%97%90/id1629210905';
    };

    return (
        <>
            <Main1>
                <div style={{ textAlign: 'center' }}>
                    <img src={main1_titleM} style={{ width: '20rem', padding: '10% 0 5% 0' }}/><br/>
                    <img src={main1_imageM} style={{ width: '20rem', padding: '0 0 5% 10%' }}/><br/>
                </div>
                <Store>
                    <img src={google} style={{ width: '10rem', margin: '0 auto' }} onClick={(e) => handleMoveGoogle(e)}/>
                    <img src={apple} style={{ width: '10rem', margin: '0 auto' }} onClick={(e) => handleMoveApple(e)}/>
                </Store>
            </Main1>
            <img src={background1M} style={{ width: '100%'}}/>

            <Main2>
                <img src={main2_imageM} style={{ width: '80%', margin: '10% 0' }}/><br/>
            </Main2>

            <Main3>
                <div style={{ width: '90%', textAlign: 'right' }}>
                    <img src={main3_messageM} style={{ width: '15%', marginLeft: 'auto' }}/><br/>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <img src={main3_titleM} style={{ width: '70%'}}/><br/>
                    <img src={main3_contentsM} style={{ width: '70%', marginTop: '5%' }}/><br/>
                    <img src={main3_imageM} style={{ width: '90%', marginTop: '10%'}}/>
                </div>
            </Main3>

            <Main4_1>
                <div style={{ width: '85%', textAlign: 'right' }}>
                    <img src={main4_1_check} style={{ width: '10%', marginLeft: 'auto' }}/><br/>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <img src={main4_1_title} style={{ width: '60%'}}/><br/>
                    <img src={main4_1_contentsM} style={{ width: '75%', marginTop: '5%' }}/><br/>
                    <img src={main4_1_image} style={{ width: '90%', marginTop: '10%'}}/>
                </div>
            </Main4_1>

            <Main4>
                <div style={{ width: '85%', textAlign: 'right' }}>
                    <img src={main4_bellM} style={{ width: '10%', marginLeft: 'auto' }}/><br/>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <img src={main4_titleM} style={{ width: '70%'}}/><br/>
                    <img src={main4_contentsM} style={{ width: '70%', marginTop: '5%' }}/><br/>
                    <img src={main4_imageM} style={{ width: '85%', marginTop: '10%'}}/>
                </div>
            </Main4>

            <Main5>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <img src={main5_liveM} style={{ width: '15%', marginLeft: 'auto', marginBottom: '3%' }}/><br/>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <img src={main5_titleM} style={{ width: '70%'}}/><br/>
                    <img src={main5_contentsM} style={{ width: '70%', marginTop: '5%' }}/><br/>
                    <img src={main5_imageM} style={{ width: '90%', marginTop: '10%'}}/>
                </div>
            </Main5>

            <Main6>
                <div style={{ textAlign: 'center' }}>
                    <img src={main6_titleM} style={{ width: '70%'}}/><br/>
                    <img src={main6_contentsM} style={{ width: '65%', marginTop: '10%' }}/><br/>
                    <img src={main6_imageM} style={{ width: '90%', marginTop: '10%'}}/>
                </div>
            </Main6>

            <Main7>
                <form ref={form}>
                    <Content>
                        <div style={{ textAlign: 'center' }}>
                            <img src={main7_titleM} style={{ width: '80%' }}/>
                        </div>
                        <div style={{ width: '90%',  margin: '5% 0 10% 10%' }}>
                            <img src={main7_contentsM} style={{ width: '60%' }}/>
                        </div>
                        <BoxRow>
                            <Font>이름</Font>
                            <input type="text" name="user_name"  placeholder="홍길동" />
                        </BoxRow>
                        <BoxRow>
                            <Font>이메일</Font>
                            <input type="email" name="user_email"  value={email} placeholder="이메일을 입력해주세요" onChange={(e) => setEmail(e.target.value)}/>
                        </BoxRow>
                        <BoxRow>
                            <Font>전화번호</Font>
                            <input type="tel" name="user_tel" placeholder="-없이 숫자만" />
                        </BoxRow>
                        <BoxRow>
                            <Font style={{ marginTop: '20%' }}>내용</Font>
                            <textarea name="message" placeholder="내용을 입력해주세요." value={content} onChange={(e) => setContent(e.target.value)}/>
                        </BoxRow>
                    </Content>
                </form>
                <Font2>개인 정보 수집·이용에 동의하십니까?</Font2>
                <div style={{ textAlign: 'center', fontSize: '1.2rem', marginTop: '5%' }}>
                    <input style={{  }}type="radio" name="chk_info"  checked={checked} onClick={diff1}/><label>동의</label>
                    <input style={{ marginLeft: '3%' }}type="radio" name="chk_info"  checked={secondChecked} onClick={diff2}/><label>비동의</label>
                </div>
                <div style={{ textAlign: 'center',  marginTop: '7%' }}>
                    <img src={main7_agreeM} style={{ width: '50%' }} onClick={sendEmail}/>
                </div>
            </Main7>

            <Footer>
                <Store style={{ width: '90%' }}>
                    <img src={google_footer} style={{ margin: '0 auto' }} onClick={(e) => handleMoveGoogle(e)}/>
                    <img src={apple_footer} style={{ margin: '0 auto' }} onClick={(e) => handleMoveApple(e)}/>
                </Store>
            </Footer>
            <img src={footerM} style={{ width: '100%' }}/>
        </>
    )
}

export default LandingMobile;